import React from "react";

TopFormWithCenter.Header = ({ children }) => {
  return <h1 className="wv-lg-heading">{children}</h1>;
};
TopFormWithCenter.Content = ({ children }) => {
  return <p className="wv-md-heading">{children}</p>;
};

export default function TopFormWithCenter({ children }) {
  return (
    <div className="container text-center">
      <div className="row">
        <div className="back_features">
          <div className="back_features_page  font-section-normal-text-medium">
            <a href="/conversational-ai-platform/chatbot-whitepaper" className="url_manipulation">
              <span>←</span>Back to Whitepaper
            </a>
          </div>
        </div>

        <div className="knowledge_header_form">
          <div className="main-slider-button beta-button">{children}</div>
        </div>
      </div>
    </div>
  );
}
