import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Card = ({ href, header, content, backgroundClass }) => {
  return (
    <li className={`${"cards__item"} ${backgroundClass}`}>
      <AnchorLink offset={100} href={href}>
        <div className="card">
          <div className="card__content">
            <div className="card__title">{header}</div>
            <p className="card__text">{content}</p>
          </div>
        </div>
      </AnchorLink>
    </li>
  );
};
export default function Cards({ cards, header, id }) {
  return (
    <div className="resources_card knowledge_cards" id={id}>
      <section className="whitepaper_cards">
        <div className="container">
          <div className="row">
            {header ? <h2>{header}</h2> : ""}
            <ul className="cards">
              {cards.map(card => (
                <Card {...card} />
              ))}
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}
