import React, { useEffect, useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import KeyResources from "../../components/common/KeyResources";
import TopFormWithCenter from "../../components/common/knowledge/Header";
import Cards from "../../components/common/knowledge/Cards";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";
import {GoToPopup} from "../homepage"
import {CookiesPoup} from "../homepage"

export const blogImg = require("../../assets/img/key-resources/bc_1_1.png");
export const whitepaperImg = require("../../assets/img/key-resources/wp_1_1.png");
export const videoImg = require("../../assets/img/key-resources/vc_1_1.png");

export const tick_icon = require("../../assets/img/tick-icon.png");

const resoucesData = [
  {
    image: blogImg,
    header:
      "Start your journey with AI, to transform workplace support and deliver exceptional user experience  ",
    url:
      "https://blog.workativ.com/conversational-ai-focus-on-user-experience/",
    link: "Read Blog",
    alt: "blog"
  },
  {
    image: whitepaperImg,
    header:
      "Fully-automated solutions deliver a better user experience and increase employee productivity.",
    url: "/conversational-ai-platform/general-vs-true-automation",
    link: "Read Whitepaper",
    alt: "whitepaper"
  },
  {
    image: videoImg,
    header: "How to create an AI Conversational Chatbot for IT Help desk ",
    url: "https://youtu.be/Xzr-lbZzNkU",
    link: "Watch Now",
    alt: "videoImg"
  }
];
export const cardData = [
  {
    href: "#section-1",
    header: "Impact of poor self-service",
    content:
      "AI and automation on the help desk is a reinvention in terms of receiving support and employees managing and supporting themselves autonomously.",
    backgroundClass: "card_one"
  },
  {
    href: "#section-2",
    header: "The benefits of having AI on your helpdesk",
    content:
      "AI is the crux of intelligent automation systems and is essential for providing contextual and better support.",
    backgroundClass: "card_two"
  },
  {
    href: "#section-3",
    header: "An end to end solution with automation and AI",
    content:
      "Workativ Assistant - An intelligent automation-based platform to build and deploy a purpose-built Virtual Assistant; for workplace IT support.",
    backgroundClass: "card_three"
  }
];

export default function KnowledgePage() {
  const [isOpen, setOpen] = React.useState(false);

  const [isActive, setIsActive] = React.useState();

  return (
    <>
      <TitleAndMetaTags
        title="Impact of AI in ITSM with IT Helpdesk Automation | IT Help Desk Chatbot | Workativ Assistant"
        description="Conversational AI Automation platform to automate ITSM Ticketing tasks. Learn how to automate IT & HR support with AI Chatbot with process automation."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogTitle="Impact of AI in ITSM with IT Helpdesk Automation | IT Help Desk Chatbot | Workativ Assistant"
        ogDescription="Conversational AI Automation platform to automate ITSM Ticketing tasks. Learn how to automate IT & HR support with AI Chatbot with process automation."
      />
      <Container>
      <Layout backgroundColor={"--green-bg mb-0"} logoFor="ASSISTANT">
          <>
            <div>
              <section
                className="wv-banner wv-landing-page-1 --green-bg wv-banner-h320"
                id="scrollToHome"
              >
                <TopFormWithCenter>
                  <TopFormWithCenter.Content>
                    Impact of AI and Automation on Helpdesk
                  </TopFormWithCenter.Content>
                  <TopFormWithCenter.Header>
                    Enhance support with smart technology
                  </TopFormWithCenter.Header>

                  <RequestForm isFooterForm={false} />
                </TopFormWithCenter>
         
              </section>
              <section className="wv-section-mv-p pb-200 content_para">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <p className="wv-md-heading wv-weight-normal wv-text-primary wv-limited-content mb-4 mx-auto">
                        The operation of an essential service desk would involve
                        a team taking details, creating tickets, and dispatching
                        the tickets to the right SME. A more upgraded service
                        desk support resolves issues over the phone, allows
                        users to create and update their tickets. It would also
                        have an updated knowledge management content, to allow
                        users to troubleshoot the problems on their own.The more
                        advanced service desk operations involve service
                        catalogs that showcase standard service offerings and
                        the service delivery expectations to the end-users.
                      </p>
                      <hr className="wv-limited-content wv-section-mv-m mx-auto mt-5 mb-5" />
                      <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mx-auto mb-3">
                        The model has worked for many years but, for
                        organizations on a growth trajectory, the cracks begin
                        to show in the service desk operations. The requests
                        start piling up, and the IT internal support team is
                        unable to handle the inflow. Requests are managed by
                        prioritizing and categorizing tickets, with no means of
                        quicker resolution time.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <Cards cards={cardData} />
              <section className="bg_section_knowledge self_service_an">
                <div className="container">
                  <div className="row">
                    <div className="col pr-0 pl-0  -call-reflection">
                      <div className="">
                        <div className="main-slider-left">
                          <h1>
                            Only a truly automated solution can be a
                            cost-effective and productive solution.
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="wv-main-content wv-section-mv-p0">
                <div className="container">
                  <div className="row">
                    <div className="col-md-3 col-sm-12 col-xs-12 --sh-sm mb-4">
                      <div className="dropdown wv-dropdown">
                        <button
                          className={`link ${isOpen ? "active" : ""}`}
                          onClick={() => setOpen(!isOpen)}
                        >
                          Explore this articles
                        </button>
                        <div
                          id="myDropdown"
                          className={`accordion-item submenu dropdown-content ${
                            !isOpen ? "collapsed" : ""
                          }`}
                        >
                          <li>
                            <AnchorLink
                              offset={300}
                              className="url_manipulation"
                              href="#section-1"
                            >
                              Think AI
                            </AnchorLink>
                          </li>
                          <li>
                            <AnchorLink
                              offset={280}
                              className="url_manipulation"
                              href="#section-2"
                            >
                              The benefits of AI
                            </AnchorLink>
                          </li>
                          <li>
                            <AnchorLink
                              offset={280}
                              className="url_manipulation"
                              href="#section-3"
                            >
                              An end to end solution
                            </AnchorLink>
                          </li>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 --hd-sm">
                      <aside className="wv-sidenav max-width-255" id="myHeader">
                        <p className="wv-sm-heading">Explore this article</p>
                        <ul className="mb-5">
                          <li>
                            <AnchorLink
                              offset={() => 100}
                              href="#section-1"
                              className={
                                isActive === "Think AI"
                                  ? "url_manipulation active"
                                  : "url_manipulation"
                              }
                              onClick={() => {
                                setIsActive("Think AI");
                              }}
                            >
                              {" "}
                              Think AI
                            </AnchorLink>
                          </li>
                          <li>
                            <AnchorLink
                              offset={() => 100}
                              href="#section-2"
                              className={
                                isActive === "The benefits of AI"
                                  ? "url_manipulation active"
                                  : "url_manipulation"
                              }
                              onClick={() => {
                                setIsActive("The benefits of AI");
                              }}
                            >
                              {" "}
                              The benefits of AI
                            </AnchorLink>
                          </li>
                          <li>
                            <AnchorLink
                              offset={() => 100}
                              href="#section-3"
                              className={
                                isActive === "An end to end solution"
                                  ? "url_manipulation active"
                                  : "url_manipulation"
                              }
                              onClick={() => {
                                setIsActive("An end to end solution");
                              }}
                            >
                              {" "}
                              An end to end solution
                            </AnchorLink>
                          </li>
                        </ul>
                        <AnchorLink offset={200} href="#scrollToHome">
                          {" "}
                          Back to top ↑
                        </AnchorLink>
                      </aside>
                    </div>
                    <div className="col-md-9 col-sm-12 col-xs-12">
                      <div className="wv-content-area">
                        <section className="wv-content-section">
                          <div id="section-1">
                            <div className="row">
                              <div className="col">
                                <h4 className="wv-heading-h4 mb-2">Think AI</h4>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3 ">
                                  AI and automation on the help desk is a
                                  reinvention in terms of receiving support and
                                  employees managing and supporting themselves
                                  autonomously.
                                </p>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3 ">
                                  A revolutionary self-service model enables
                                  users to engage with the IT support team only
                                  when necessary, leaving support teams only the
                                  complex tasks to handle.
                                </p>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                  The impact at large is that it maximizes
                                  productivity and delivers a better user
                                  experience while significantly bringing down
                                  helpdesk costs.
                                </p>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section className="wv-content-section">
                          <section
                            className="benefits_knowledge"
                            id="section-2"
                          >
                            <div className="container">
                              <div className="row">
                                <div className="">
                                  <div className="">
                                    <section className="cards_without_bg">
                                      <h4 className="wv-heading-h4 mb-2">
                                        The benefits of automated resolution
                                      </h4>
                                      <div className="row">
                                        <div className="col-md-6 col-sm-12 col-xs-12 mb-4">
                                          <img loading = "lazy"
                                            src={tick_icon}
                                            rel="Tick icon"
                                            height={32}
                                            width={32}
                                            className="mb-4 max-width-35"
                                          />
                                          <h4 className="wv-heading-h4 mb-2">
                                            It’s quicker
                                          </h4>
                                          <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                            No browsing a knowledge base to get
                                            the answer resolved.
                                          </p>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-xs-12 mb-4">
                                          <img loading = "lazy"
                                            src={tick_icon}
                                            rel="Tick icon"
                                            height={32}
                                            width={32}
                                            className="mb-4 max-width-35"
                                          />
                                          <h4 className="wv-heading-h4 mb-2">
                                            No human intervention
                                          </h4>
                                          <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                            The assistant is designed with
                                            automation capabilities to resolve
                                            issues instantly, thereby deflecting
                                            calls away from the helpdesk.
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                          <img loading = "lazy"
                                            src={tick_icon}
                                            rel="Tick icon"
                                            height={32}
                                            width={32}
                                            className="mb-4 max-width-35"
                                          />
                                          <h4 className="wv-heading-h4 mb-2">
                                            Reduced wait times
                                          </h4>
                                          <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                            Employees won’t be checking the
                                            status of their tickets as much as
                                            they used to because of the
                                            innovative self-service you provide.
                                            You’ll find that tickets reach a
                                            close faster.
                                          </p>
                                        </div>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </section>
                        <section className="wv-section-mv-p content_para content_ul">
                          <div className="container">
                            <div className="row">
                              <div className="">
                                <h4 className="wv-heading-h4 mb-2">
                                  Accelerated resolution process
                                </h4>
                                <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                  AI on the helpdesk can boost speed and enhance
                                  support efforts with automated resolution. Why
                                  AI-powered virtual assistant?
                                </p>
                                <ul className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content" style={{ width: "100%", float: "left" }}>
                                  <li>Call deflection</li>
                                  <li>Faster and automated resolution</li>
                                  <li>Better productivity</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section className="wv-section-mv-p content_para">
                          <div id="section-3">
                            <div className="container">
                              <div className="row">
                                <div className="">
                                  <h4 className="wv-heading-h4 mb-2">
                                    An end to end solution with automation and
                                    AI
                                  </h4>
                                  <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                    Workativ Assistant is an intelligent
                                    automation-based platform to build and
                                    deploy purpose-built Virtual Assistant, for
                                    workplace IT support.
                                  </p>
                                  <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                    Workativ Assistant brings a modern
                                    self-service experience where employees can
                                    seek assistance, resolve and close issues
                                    autonomously, eliminating the need to wait
                                    in queue for ticket resolution.
                                  </p>
                                  <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                    The automation + AI engine lets you do more
                                    than resolve issues; you can also get tasks
                                    completed in minutes.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
          <KeyResources cards={resoucesData} />
          {/* <GoToPopup />
          <CookiesPoup /> */}
          <RequestForm isFooterForm={true} />
        </Layout>
        {/* <OnScrollPopup /> */}
      </Container>
    </>
  );
}
